@tailwind base;
@tailwind components;
@tailwind utilities;

@import "rsuite/dist/rsuite.css";



::-webkit-scrollbar {
    width: 10px;
}
  

::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
    
}


::-webkit-scrollbar-thumb:hover {
    background: #555;
}



input[type="file"] {
    display: none;
}


